
  import { Component, Vue } from 'vue-property-decorator'
  import { GPdf } from '@/components/dataTables/PDF/GPdf'
  import jsPDF from 'jspdf'
  import { LinkedCellOptions } from '@/components/dataTables/cell/index'
  import { plainToInstance } from 'class-transformer'
  import dayjs from 'dayjs'
  import { PurchaseOrder } from '@/entities/purchase'
  import { mapGetters } from 'vuex'
  import { fixPrice, fixThousands } from '@/utils/general'

@Component({
  computed: {
    ...mapGetters('app', ['system']),
  },
})
  export default class responsabilityPDF extends GPdf {
  declare options: LinkedCellOptions
  loading = false;
  imgUrl = '@/assets/companyLogo/logo.jpg'; // URL de la imagen
  carPhoto
  purchaseOrder = plainToInstance(PurchaseOrder, {})
  disabledPdf = true

  system!: string

  async mounted () {
    const { item: { id } } = this
    this.purchaseOrder = await this.fetchData({
      query: { name: 'fetch', model: 'PurchaseOrder', params: { id } },
      force: true,
    })

    this.disabledPdf = this.purchaseOrder.status.status.name !== 'approved'
  }

  async generatePDF () {
    this.loading = true

    this.purchaseOrder = await this.fetchData({
      query: { name: 'fetch', model: 'PurchaseOrder', params: { id: this.item.id } },
      force: true,
    })

    this.purchaseOrder.negotiation = await this.fetchData({
      query: { name: 'fetch', model: 'Negotiation', params: { id: this.purchaseOrder.negotiation.id } },
      force: true,
    })

    this.purchaseOrder.negotiation.inspection.appraisal.deal = await this.fetchData({
      query: { name: 'fetch', model: 'Deal', params: { id: this.purchaseOrder.negotiation.inspection.appraisal.deal.id } },
      force: true,
    })

    this.purchaseOrder.negotiation.inspection.appraisal.deal.stock = await this.fetchData({
      query: { name: 'find', model: 'Stock' },
      filter: { deals: { id: { _eq: this.purchaseOrder.negotiation.inspection.appraisal.deal.id } } },
    })

    const fileParameter = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: {
        _and: [
          { process: { table_name: { _eq: 'inspection' } } },
          { file_type: { name: { _eq: 'photo' } } },
          { name: { _eq: 'front_pilot' } },
        ],
      },
    })

    const fileProcess = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: this.purchaseOrder.negotiation.inspection.id } },
          { id_file_parameter: { _eq: fileParameter[0].id } },
        ],
      },
      force: true,
    })

    this.carPhoto = fileProcess[0]?.file?.largeUrl

    const pdfContent = this.$refs.pdfContent
    const element = pdfContent instanceof HTMLElement ? pdfContent : (pdfContent as Vue).$el as HTMLElement

    const JsPDF = jsPDF
    const doc = new JsPDF('p', 'mm', 'a4')
    this.setFont(doc)

    // Coordenadas iniciales
    const x = 10
    const y = 20

    doc.setLineWidth(400)

    // Llenar el PDF con el contenido
    await this.page1(doc, y, x)

    // Convertir el contenido del HTML a texto en el PDF
    this.addHtmlContent(doc, element, x, y)
  }

  addSubtitle (doc, x, y) {
    const documentId = `N°${dayjs(this.purchaseOrder.createdAt).format('YYYY')}${'0'.repeat(9 - String(this.purchaseOrder.id).length)}${this.purchaseOrder.id}`
    const hourText = `Hora: ${dayjs(this.purchaseOrder.createdAt).format('HH:mm')}`
    const dateText = `Fecha: ${dayjs(this.purchaseOrder.createdAt).format('DD/MM/YYYY')}`
    doc.text(documentId, x, y)
    doc.text(hourText, this.getCenterWidth(doc, hourText), y)
    doc.text(dateText, this.getAlignEnd(doc, dateText), y)
    y += 5
    y = this.separator(doc, y)
    return y
  }

  async addCarData (doc, x, y) {
    const auto = this.purchaseOrder.negotiation.inspection.appraisal.deal.auto
    const process = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'inspection' } },
      force: true,
    }))[0]
    const component = (await this.fetchData({
      query: { name: 'find', model: 'Component' },
      filter: { slug: { _eq: 'mileage' } },
      force: true,
    }))[0]
    const mileage = (await this.fetchData({
      query: { name: 'find', model: 'AttributeView' },
      filter: {
        _and: [
          { id_process: { _eq: process.id } },
          { id_process_record: { _eq: this.purchaseOrder.negotiation.inspection.id } },
          { id_component: { _eq: component.id } },
        ],
      },
    }))[0]

    y = this.addSectionTitle(doc, x, y, 'Datos del vehículo')
    if (this.carPhoto) {
      doc.addImage(this.carPhoto, 'JPEG', x, y - 3, 50, 35)
    }
    y = this.insertList(doc, x + (this.carPhoto ? 55 : 0), y, [
        'Patente',
        'Año',
        'Marca',
        'Modelo',
        'Kilometraje',
        'Precio de compra',
      ],
      [
        auto.registrationPlate + '|bold',
        String(auto.version.year.id),
        auto.version.version.model.brand.name,
        auto.version.version.model.name + ' ' + auto.version.version.name + '|bold',
        `${fixThousands(mileage.value)} kms`,
        fixPrice(String(this.purchaseOrder.agreedAmount)) + '|bold',
      ])

    y = this.separator(doc, y)

    return y
  }

  async addSeller (doc: jsPDF, x, y) {
    const client = this.purchaseOrder.negotiation.inspection.appraisal.deal.lead.client
    const owner = this.purchaseOrder.negotiation.inspection.appraisal.deal.ownership.owners[0].person

    const intermediary = client.id !== owner.id ? client : undefined

    const address = (await this.fetchData({
      query: { name: 'find', model: 'PersonAddress' },
      filter: { id_person: { _eq: owner.id } },
    }))?.[0]
    const addressString = address ? `${address.address?.streetName} ${address.address?.streetNumber}, ${address.address?.city?.name}`.toUpperCase() : undefined
    y = this.addSectionTitle(doc, x, y, 'Propietario')
    y = this.insertList(doc, x, y, [
        owner.type.name === 'company' ? 'Empresa' : 'Nombres',
        owner.type.name === 'company' ? undefined : 'Apellidos',
        'Rut',
        'Teléfono',
        owner.email ? 'Mail' : undefined,
        address ? 'Dirección' : undefined,
      ].filter(item => item !== undefined),
      [
        (owner.type.name === 'company' ? owner.companyName : `${owner.firstName?.split(' ')?.[0] || ''} ${owner.secondName?.split(' ')?.[0] || ''}`.toUpperCase()).toUpperCase() + '|bold', // nombres
        owner.type.name === 'company' ? undefined : `${owner.surname?.split(' ')?.[0] || ''} ${owner.secondSurname?.split(' ')?.[0] || ''}`.toUpperCase() + '|bold', // apellidos
        owner.uid, // rut
        owner.phone, // telefono
        owner?.email?.personal || owner?.email?.work || undefined, // mail
        addressString, // direccion
      ].filter(item => item !== undefined))

    if (intermediary) {
      y = this.separator(doc, y)
      const address = (await this.fetchData({
        query: { name: 'find', model: 'PersonAddress' },
        filter: { id_person: { _eq: intermediary.id } },
      }))?.[0]
      const addressString = address ? `${address.address?.streetName} ${address.address?.streetNumber}, ${address.address?.city?.name}`.toUpperCase() : undefined
      y = this.addSectionTitle(doc, x, y, 'Intermediario')
      y = this.insertList(doc, x, y, [
          'Nombres',
          'Apellidos',
          'Rut',
          'Teléfono',
          intermediary.email ? 'Mail' : undefined,
          address ? 'Dirección' : undefined,
        ].filter(item => item !== undefined),
        [
          `${intermediary.firstName?.split(' ')?.[0] || ''} ${intermediary.secondName?.split(' ')?.[0] || ''}`.toUpperCase() + '|bold', // nombres
          `${intermediary.surname?.split(' ')?.[0] || ''} ${intermediary.secondSurname?.split(' ')?.[0] || ''}`.toUpperCase() + '|bold', // apellidos
          intermediary.uid, // rut
          intermediary.phone, // telefono
          intermediary?.email?.personal || intermediary?.email?.work || undefined, // mail
          addressString, // direccion
        ].filter(item => item !== undefined))
    }
    return y
  }

  async addClosingSection (doc: jsPDF) {
    const executive = this.purchaseOrder.negotiation.inspection.appraisal.deal.lead.executive
    const clientService = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: 1 } },
      force: true,
    })
    this.addClosing(doc, [
        'Asesor comercial:',
        `${executive.person.firstName} ${executive.person.surname}`,
        `Tel: ${executive.person.phoneWork || 'No informado'}`,
        executive.person.email.work || 'Email no informado',
      ],
      [
        'Atención al cliente:',
        'Tel: ' + clientService.phoneWork,
      ],
    )
  }

  async addResponsability (doc: jsPDF, x, y) {
    const process = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'purchase_order' } },
      force: true,
    }))[0]
    if (await process) {
      if (y > doc.internal.pageSize.getHeight() - 80) {
        y = this.newPage(doc, y)
        this.addClosingSection(doc)
      }
      y = this.addSectionTitle(doc, x, y, 'Responsabilidad civil y penal')
      doc.text(process.config.responsibility_letter.legal.text.split('$system').join(this.system), x, y)
      y += 7
      y = this.insertList(doc, x, y, process.config.responsibility_letter.legal.list, [])
      y = this.separator(doc, y)
      if (y > doc.internal.pageSize.getHeight() - 60) {
        y = this.newPage(doc, y)
        await this.addClosingSection(doc)
      }
      y = this.addSectionTitle(doc, x, y, 'Compromiso de transferencia')
      y = this.addParagraph(doc, x, y, process.config.responsibility_letter.compromise.text)
      y += 7
      y = this.addParagraph(doc, x, y, 'Agradecemos su confianza y le contactaremos para hacerle entrega del contrato de compraventa una vez esté legalizado ante notario.')
    }
    return y
  }

  async page1 (doc: jsPDF, y, x) {
    const system = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: 1 } },
      force: true,
    })

    const imageData = system.photo
    const pageWidth = doc.internal.pageSize.getWidth()

    if (imageData) {
      doc.addImage(imageData, 'JPEG', (pageWidth / 2 - 30), 10, 60, 6)
      y += 5
    } else {
      y -= 10
    }
    this.footer(doc)
    await this.addClosingSection(doc)
    y = this.separator(doc, y)
    y = this.addTitle(doc, y, 'CARTA DE RESPONSABILIDAD')
    y = this.addSubtitle(doc, x, y)
    y = await this.addCarData(doc, x, y)
    y = await this.addSeller(doc, x, y)
    y = this.separator(doc, y)
    await this.addResponsability(doc, x, y)
  }

  addHtmlContent (doc: jsPDF, element: HTMLElement, x: number, y: number) {
    doc.html(element, {
      callback: doc => {
        this.loading = false
        doc.save(`responsabilidad ${this.purchaseOrder.negotiation.inspection.appraisal.deal.lead.client.fullName} ${this.purchaseOrder.negotiation.inspection.appraisal.deal.lead.client.uid}.pdf`)
      },
      x,
      y, // Ajustar la posición y según la altura de la imagen y el texto
      html2canvas: { scale: 0.5 }, // Ajustar el escalado si es necesario
    })
  }

  get action () {
    return this.options?.action
  }

  get icon () {
    const { action, item } = this

    return action?.icon || item?.icon
  }

  get iconColor () {
    const { options } = this

    return options?.action?.iconColor || 'white'
  }

  get color () {
    const { action, item } = this

    return action?.color || item?.color
  }

  get tooltip () {
    const { options, item } = this

    return options?.tooltip || item?.tooltip || item?.name || item?.contact?.name
  }

  get disabledButton () {
    const { item } = this

    return !item?.id
  }
  }
